import { useParams } from '@reach/router';
import classNames from 'classnames';
import { Link, graphql } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import { useQuery } from 'urql';

import { useGetPlayerOverviewPageLink } from '@/bits/links/useLink';
import {
  Button,
  Card,
  CardBody,
  CardCloseButton,
  CardOptions,
  CardOptionsButton,
  ControlledModal,
  InlineIconButton,
  Table,
  Value,
} from '@/components';
import {
  EditIcon,
  EyeIcon,
  InformationIcon,
  RefreshIcon,
} from '@/components/icons';
import { useTranslate } from '@/contexts';
import { PlayerRiskLevelForm, SubmitReviewStatusForm } from '@/forms';
import { ReviewStatus } from '@/globalTypes';
import { Nullable } from '@/types/Nullable';
import formatDate from '@/utils/formatter/formatDate';
import { getRiskLevelColor } from '../risk-assessment-block/component';
import {
  RiskAssessment,
  RiskAssessmentVariables,
} from './__generated__/RiskAssessment';
import { SanityPlayerRiskAssessmentOverviewBlockFragment } from './__generated__/SanityPlayerRiskAssessmentOverviewBlockFragment';

export const Fragment = graphql`
  fragment SanityPlayerRiskAssessmentOverviewBlockFragment on SanityPlayerRiskAssessmentOverviewBlock {
    totalRiskAssessment {
      ...LocaleString
    }
    riskLevel {
      ...LocaleString
    }
    finalScore {
      ...LocaleString
    }
    calculatedAt {
      ...LocaleString
    }
    status {
      ...LocaleString
    }
    lastReviewed {
      ...LocaleString
    }
    overriddenRiskLevel {
      ...LocaleString
    }
    regions {
      ...LocaleString
    }
    regionWeightPercentage {
      ...LocaleString
    }
    brands {
      ...LocaleString
    }
    score {
      ...LocaleString
    }
    percentage {
      ...LocaleString
    }
    ruleType {
      ...LocaleString
    }
    agentId {
      ...LocaleString
    }
    action {
      ...LocaleString
    }
    note {
      ...LocaleString
    }
    maxScore {
      ...LocaleString
    }
    dateTime {
      ...LocaleString
    }
    kycAction {
      ...LocaleString
    }
    manualCheckAction {
      ...LocaleString
    }
    blockAction {
      ...LocaleString
    }
    currentReviewStatus {
      ...LocaleString
    }
    isReviewRequired {
      ...LocaleString
    }
    reviewRequired {
      ...LocaleString
    }
  }
`;

const QUERY = gql`
  query RiskAssessment($playerGlobalId: String!) {
    viewer {
      id
      riskAssessment(playerGlobalId: $playerGlobalId) {
        playerGlobalId
        kycCheckStatus {
          status
        }
        players {
          id
          rawPlayerId
          brand {
            name
          }
        }
        finalScore
        riskLevel
        overriddenRiskLevel
        score
        maxScore
        calculatedAt
        actions
        reviewStatusInfo {
          status
          lastReviewed
        }
        regions {
          regionName
          score
          maxScore
          percentage
          weight
          finalScore
          risks {
            riskName
            maxScore
            riskLevel
            score
            calculatedAt
            reviewRequired
          }
        }
        reviewTrigger {
          byRisks {
            regionId
            riskId
          }
          byOverallRiskLevel
        }
      }
    }
  }
`;

const PlayerRiskAssessmentOverviewBlock: FC<{
  block: SanityPlayerRiskAssessmentOverviewBlockFragment;
}> = ({ block }) => {
  const { t } = useTranslate();
  const params = useParams();
  const getPlayerOverviewLink = useGetPlayerOverviewPageLink();

  const [{ data, fetching }, refresh] = useQuery<
    RiskAssessment,
    RiskAssessmentVariables
  >({
    query: QUERY,
    variables: {
      playerGlobalId: params.playerGlobalId,
    },
  });

  const riskAssessment = data?.viewer.riskAssessment;
  const players = riskAssessment?.players || [];

  const disableResolveButton = (reviewStatus: Nullable<string>) => {
    return reviewStatus === ReviewStatus.ReviewNotNeeded;
  };

  return (
    <>
      <Card
        size="lg"
        title={t(block.totalRiskAssessment)}
        options={
          <CardOptions>
            <CardOptionsButton
              className="flex"
              onClick={() => refresh({ requestPolicy: 'network-only' })}
            >
              <RefreshIcon />
            </CardOptionsButton>
          </CardOptions>
        }
      >
        <CardBody>
          <div className="p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
            <Value fetching={fetching} title={t(block.riskLevel)}>
              <div className="flex items-center">
                <span>{riskAssessment?.riskLevel}</span>
                {riskAssessment?.overriddenRiskLevel && (
                  <span className={'text-red-600'}>*</span>
                )}
                <InformationIcon
                  className={classNames(
                    'inline-block',
                    'ml-2',
                    'text-lg',
                    getRiskLevelColor(riskAssessment?.riskLevel),
                  )}
                />
                <ControlledModal
                  content={
                    riskAssessment?.riskLevel ? (
                      <PlayerRiskLevelForm
                        riskLevel={riskAssessment.riskLevel}
                        playerGlobalId={params?.playerGlobalId}
                      />
                    ) : null
                  }
                >
                  <InlineIconButton>
                    <EditIcon />
                  </InlineIconButton>
                </ControlledModal>
              </div>
            </Value>

            <Value
              fetching={fetching}
              title={t(block.finalScore)}
              value={
                riskAssessment?.finalScore
                  ? `${riskAssessment.finalScore}%`
                  : ''
              }
            />
            <Value
              fetching={fetching}
              title={t(block.calculatedAt)}
              value={formatDate(riskAssessment?.calculatedAt)}
            />
            <Value fetching={fetching} title="">
              <ControlledModal
                content={
                  riskAssessment?.reviewStatusInfo.status ? (
                    <SubmitReviewStatusForm
                      currentReviewStatus={
                        riskAssessment.reviewStatusInfo.status
                      }
                      playerGlobalId={params?.playerGlobalId}
                      reviewTrigger={riskAssessment.reviewTrigger}
                    />
                  ) : null
                }
              >
                <Button
                  disabled={disableResolveButton(
                    riskAssessment?.reviewStatusInfo.status,
                  )}
                  variant="primary"
                  type="button"
                >
                  Update Review Status
                </Button>
              </ControlledModal>
            </Value>
            <Value
              fetching={fetching}
              title={t(block.status)}
              value={riskAssessment?.kycCheckStatus?.status}
            />
            <Value fetching={fetching} title={t(block.brands)}>
              <div className="flex items-center">
                {players.length < 3 ? (
                  <span>
                    {players.map((player, i) => {
                      return (
                        <div key={`playerId_${i}`}>
                          <Link
                            key={`playerIdCraLink_${i}`}
                            to={getPlayerOverviewLink(player.id) ?? ''}
                          >
                            {player.brand.name}
                          </Link>
                          {players.length !== i + 1 && <span>, </span>}
                        </div>
                      );
                    })}
                  </span>
                ) : (
                  <>
                    <span className="text-blue-600">{players.length}</span>
                    <ControlledModal
                      content={
                        <Card
                          title="Brand(s)"
                          size="lg"
                          options={
                            <CardOptions>
                              <CardCloseButton />
                            </CardOptions>
                          }
                        >
                          <div className="p-3">
                            <Table
                              headings={['Player ID', 'Brand']}
                              rows={players.map((player, key) => ({
                                key: player.id,
                                data: [
                                  <Link
                                    key={`playerIdLink_${key}`}
                                    className="w-full h-full p-3 block"
                                    to={getPlayerOverviewLink(player.id) ?? ''}
                                  >
                                    {player.rawPlayerId}
                                  </Link>,
                                  player.brand.name,
                                ],
                              }))}
                            />
                          </div>
                        </Card>
                      }
                    >
                      <InlineIconButton>
                        <EyeIcon className="text-gray-400 text-lg" />
                      </InlineIconButton>
                    </ControlledModal>
                  </>
                )}
              </div>
            </Value>
            <Value fetching={fetching} title={t(block.currentReviewStatus)}>
              {riskAssessment?.reviewStatusInfo?.status
                ? riskAssessment.reviewStatusInfo.status
                : ''}
            </Value>
            <Value fetching={fetching} title={t(block.lastReviewed)}>
              {formatDate(riskAssessment?.reviewStatusInfo?.lastReviewed)}
            </Value>
          </div>
        </CardBody>
      </Card>
      <Card size="lg" title={t(block.regions)}>
        {riskAssessment?.regions.map((region, r) => {
          return (
            <div key={`regionContainer_${r}`} className="p-3">
              <div className="p-3">
                <Table
                  key={`regionRiskTable_${r}`}
                  headings={[
                    `${region.regionName} ${t(block.regionWeightPercentage, {
                      percentage: region.weight.toFixed(2),
                    })}`,
                    t(block.riskLevel),
                    t(block.score),
                    t(block.calculatedAt),
                    t(block.isReviewRequired),
                  ]}
                  rows={region.risks.map((risk) => ({
                    key: risk.riskName,
                    data: [
                      risk.riskName,
                      risk.riskLevel,
                      risk.score,
                      formatDate(risk.calculatedAt),
                      risk.reviewRequired && (
                        <span>{t(block.reviewRequired)}</span>
                      ),
                    ],
                  }))}
                />
              </div>

              <CardBody key={`regionResult_${r}`} className="mb-5">
                <div className="p-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2 border border-solid border-gray-400">
                  <Value
                    fetching={fetching}
                    title={t(block.finalScore)}
                    value={`${region.finalScore.toFixed(2)}%`}
                  />
                  <Value
                    fetching={fetching}
                    title={t(block.percentage)}
                    value={`${region.percentage.toFixed(2)}%`}
                  />
                  <Value
                    fetching={fetching}
                    title={t(block.score)}
                    value={`${region.score}`}
                  />
                  <Value
                    fetching={fetching}
                    title={t(block.maxScore)}
                    value={`${region.maxScore}`}
                  />
                </div>
              </CardBody>
            </div>
          );
        })}
      </Card>
    </>
  );
};

export default PlayerRiskAssessmentOverviewBlock;
